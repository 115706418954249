
<template>
  <div class="login-layout">
    <div class="new-flex" v-if="!signModel">
      <div>
        <v-layout>
          <v-img height="60" contain :src="require(`../../assets/${logoUrl}`)"></v-img>
        </v-layout>
        <div class="mx-auto mt-10"><!-- elevation-8 -->
          <v-card-text class="pa-0">
            <v-layout column>
              <v-card flat class=" card-box">
                <v-form ref="loginForm" autocomplete="off" class="mt-3">
                  <v-text-field v-model="model.username" outlined dense append-icon="mdi-account"
                    @keyup.enter="loginHandler" :rules="$_requiredValidation" id="username_field"
                    :label="$t('message.login.userName')" type="text" hide-details class="mb-3"></v-text-field>
                  <v-text-field v-model="model.password" outlined dense id="password_field" :rules="$_requiredValidation"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @keyup.enter="loginHandler"
                    :label="$t('message.login.password')" :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword" hide-details class="mb-3" autocomplete="new-password"></v-text-field>
                  <v-text-field v-model="hostRef" @keyup="doAlbhaNumericConversion" outlined dense
                    @keyup.enter="loginHandler" id="domain" :rules="$_requiredValidation"
                    :label="$t('message.login.host')" type="text" :suffix="defaultHost" :hide-details="!domainHasMistake"
                    class="mb-3"></v-text-field>
                  <v-select append-icon="mdi-translate" id="lang_select" outlined dense hide-details
                    :label="$t('message.login.language')" :items="language" v-model="defaultLang" item-text="title"
                    item-value="value"></v-select>
                  <v-layout justify-center class="mt-5">
                    <v-btn class="button-deign" :loading="loading" @click="loginHandler">{{
                      $t('message.layout.login') }}</v-btn>
                  </v-layout>
                  <v-layout justify-center>
                    <p class="my-3">{{ $t('message.layout.ortext') }}</p>
                  </v-layout>
                  <v-layout justify-center @click="rfidClick">
                    <v-btn class="button-deign">
                      {{ $t('message.layout.rfidsignin') }}
                    </v-btn>
                  </v-layout>
                </v-form>
              </v-card>
            </v-layout>
          </v-card-text>
        </div>
      </div>
    </div>
    <div class="new-flex" v-else>
      <div>
        <v-layout>
          <v-img height="60" contain :src="require(`../../assets/${logoUrl}`)"></v-img>
        </v-layout>
        <div class="mx-auto mt-10"><!-- elevation-8 -->
          <v-card-text class="pa-0">
            <v-layout column>
              <v-card flat class=" card-box">
                <v-form ref="rfidform" autocomplete="off" class="mt-3">
                  <v-text-field v-model="from.password" :rules="$_requiredValidation" outlined dense id="password_field"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :label="$t('message.layout.ScanRFID')"
                    :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" hide-details
                    class="mb-3" @keyup.enter="rfidHandler" ref="passwordField"></v-text-field>
                  <v-text-field v-model="hostRefRfid" :rules="$_requiredValidation" @keyup="doAlbhaNumericConversionRfid" outlined dense
                    @keyup.enter="rfidHandler" id="domain" :label="$t('message.login.host')" type="text"
                    :suffix="defaultHost" :hide-details="!domainHasMistake" class="mb-3"></v-text-field>
                  <v-select append-icon="mdi-translate" id="lang_select" outlined dense hide-details
                    :label="$t('message.login.language')" :items="language" v-model="defaultLang" item-text="title"
                    item-value="value"></v-select>
                  <v-layout justify-center @click="signInPassword" class="mt-5">
                    <v-btn class="button-deign">
                      {{ $t('message.layout.SignInpassword') }}
                    </v-btn>
                  </v-layout>
                </v-form>
              </v-card>
            </v-layout>
          </v-card-text>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ // for local login
export default {
  data() {
    return {
      loading: false,
      model: {
        username: '',
        password: '',
        host: ''
      },
      from: {
        password: '',
        host: ''
      },
      hostRef: '',
      hostRefRfid: '',
      defaultHost: this.$store.state.common.host,
      defaultLang: window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) ? window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) : 'no',
      showPassword: false,
      notificationTitle: '',
      notificationImage: '',
      logoUrl: process.env.VUE_APP_LOGO_FILE_NAME,
      domainHasMistake: false,
      signModel: false
    }
  },
  computed: {
    language() {
      return [
        { title: this.$t('message.common.english'), value: 'en' },
        { title: this.$t('message.common.norwegian'), value: 'no' },
        { title: this.$t('message.common.lithuanian'), value: 'lt' },
        { title: this.$t('message.common.polish'), value: 'pl' },
        { title: this.$t('message.common.swedish'), value: 'sv' }
      ]
    },
    $_domainValidation() {
      var letterNumber = /^[0-9a-zA-Z]+$/
      return [
        val => {
          if (!val) {
            this.domainHasMistake = true
            return 'Required'
          }
          if (val.match(letterNumber)) {
            this.domainHasMistake = false
            return true
          } else {
            this.domainHasMistake = true
            return 'Invalid Domain Name'
          }
        }]
    }
  },
  watch: {
    defaultLang() {
      this.$i18n.locale = this.defaultLang
      window.localStorage.setItem(process.env.VUE_APP_LOCALE_STORAGE, this.defaultLang)
    }
  },
  created() {
    this.hostRef = window.localStorage.getItem(process.env.VUE_APP_DOMAIN_CACHE_NAME) ?? ''
    this.hostRefRfid = window.localStorage.getItem(process.env.VUE_APP_DOMAIN_CACHE_NAME) ?? ''
  },
  mounted() {
    switch (process.env.VUE_APP_HOST_INCLUDES) {
      case 'axentiv':
        this.notificationTitle = 'AXENTIV'
        this.notificationImage = 'axentiv'
        break
      case 'weldit':
        this.notificationTitle = 'WELD IT'
        this.notificationImage = 'weldit'
        break
      case 'novap':
        this.notificationTitle = 'NOVAP'
        this.notificationImage = 'novap'
        break
      default:
        break
    }
    /* if (this.defaultHost.includes(process.env.VUE_APP_HOST_INCLUDES)) {
      this.notificationTitle = 'AXENTIV'
      this.notificationImage = 'axentiv'
    } else {
      this.notificationTitle = 'WELD IT'
      this.notificationImage = 'weldit'
    } */
  },
  methods: {
    doAlbhaNumericConversion() {
      if (this.hostRef.length > 13) this.hostRef = this.hostRef.substring(0, 13)
      this.hostRef = this.hostRef.replace(/[^A-Za-z1-9]/g, "").toLowerCase()
    },
    loginHandler() {
      if (this.$refs.loginForm.validate()) {
        window.localStorage.removeItem('TimerPwa_weekno')
        window.localStorage.removeItem('TimerPwa_year')
        this.loading = true
        let hostRefString = this.hostRef.toLowerCase()
        hostRefString = hostRefString.replace(/[^0-9a-zA-Z]/gi, '') // to trim the space and spection characters
        if (!hostRefString.includes(this.defaultHost)) {
          this.model.host = ''
          this.model.host += `${hostRefString}${this.defaultHost}`
        } else this.model.host = `${hostRefString}`
        const authObj = { email: this.model.username, password: this.model.password }
        this.$api.execute('post', `https://${this.model.host}/api/login`, authObj).then(reponse => {
          if (reponse && reponse.data) {
            const responseData = reponse.data
            if (responseData.statusCode === 500) {
              let message = responseData[`${this.$i18n.locale}_message`] || responseData.message
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: message }) // responseData[`${this.$i18n.locale}_message`]
              this.loading = false
            } else {
              var date = new Date()
              date.setDate(date.getDate() + 4)
              this.model.password = reponse.data.password
              this.model.username = reponse.data.user.email
              this.model.hostAppToken = reponse.data.token
              const timerPwaAppUser = reponse.data.user.user_id
              this.$cookie.set(process.env.VUE_APP_HOST_USER, timerPwaAppUser, { expires: date })
              this.$cookie.set(process.env.VUE_APP_HOST_TOKEN, reponse.data.token, { expires: date })
              this.$cookie.set(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN, reponse.data.checklist_access_token, { expires: date })
              /* set user permission and roles  */
              let obj = JSON.stringify({ permissions: reponse.data.permissions || {}, role: reponse.data.role || {} })
              if (obj) {
                this.$cookie.set(process.env.VUE_APP_PERMISSIONS_AND_ROLES, this.$formatter.encryptString(obj), { expires: date })
                this.$store.commit('setPermissions', JSON.parse(obj))
              }
              /* set the taskcount in cookie and read it anywhere */
              window.localStorage.setItem(process.env.VUE_APP_TASKS_COUNT, reponse.data.task_count || 0)
              /* set initial with model and get from there */
              this.model.initialer = responseData.user.initialer || ''
              /* The app login begins from here */
              this.$store.dispatch('login', this.model)
                .then((userObj) => {
                  const timerStartup = userObj.timer_startup
                  /* On sucess Login store the domain */
                  window.localStorage.setItem(process.env.VUE_APP_DOMAIN_CACHE_NAME, hostRefString)
                  /* var date = new Date()
                  date.setDate(date.getDate() + 4)
                  this.$api.execute('get', 'generalsettings')
                    .then((response) => {
                      if (response.data && response.data[0]) {
                        const model = response.data[0]
                        const isProjectExcluded = model.exclude_project_module !== undefined && model.exclude_project_module !== null ? model.exclude_project_module : false
                        const isDeviationExcluded = model.exclude_deviation_module !== undefined && model.exclude_deviation_module !== null ? model.exclude_deviation_module : false
                        this.$store.commit('isProjectExcluded', isProjectExcluded)
                        this.$store.commit('isDeviationExcluded', isDeviationExcluded)
                        const obj = { project: isProjectExcluded ? 1 : 0, deviation: isDeviationExcluded ? 1 : 0, expires_at: date }
                        this.$cookie.set('pwaAppExcludeModules', JSON.stringify(obj), { expires: date })
                      }
                    }) */
                  const self = this
                  try {
                    // if (('Notification' in window)) {
                    //   Notification.requestPermission().then(function (result) {
                    //     self.$api.execute('get', `hours/get_not_approved_entries_count/${userObj.id}`)
                    //       .then((res) => {
                    //         const weekDetails = res.data
                    //         let showWeekDetails = ''
                    //         if (weekDetails.length > 0) {
                    //           for (let i = 0; i < weekDetails.length; i++) {
                    //             if (weekDetails.length === (i + 1)) showWeekDetails += weekDetails[i]
                    //             else showWeekDetails += weekDetails[i] + ' , '
                    //           }
                    //           if ('serviceWorker' in navigator) {
                    //             navigator.serviceWorker.getRegistration().then(function (reg) {
                    //               var options = {
                    //                 body: self.$t('message.common.not_approved_notification', { count: weekDetails.length, weeknumber: showWeekDetails }),
                    //                 icon: require(`../../../public/img/${self.notificationImage}.png`)
                    //               }
                    //               if (reg) {
                    //                 reg.showNotification(self.notificationTitle, options)
                    //               }
                    //             })
                    //           }
                    //         }
                    //       })
                    //   })
                    //   this.$eventBus.$emit('checkHoursExceeding')
                    // }
                  } catch (error) { }
                  /* get and set features */
                  this.$hostAppApi.execute('get', `https://${this.model.host}/api/check_and_get_tenant_features?user_id=${timerPwaAppUser}`)
                    .then((response) => {
                      if (response && response.data && response.data.features) {
                        window.localStorage.setItem(process.env.VUE_APP_FEATURES_LIST, this.$formatter.encryptString(JSON.stringify(response.data.features)))
                      }
                    })
                    .finally(() => {
                      timerStartup === 1 ? this.$router.push('/calendar') : timerStartup === 2 ? this.$router.push('/stemp') : this.$router.push('/dashboard')
                    })
                })
                .catch((e) => {
                  this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.pwaNotEnabled' })
                })
                .finally(() => {
                  this.loading = false
                })
            }
          }
        }).catch((e) => {
          console.log('Host app api login exception:' + e)
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.login.authFailed') })
          this.loading = false
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    doAlbhaNumericConversionRfid() {
      // alert();
      if (this.hostRefRfid.length > 13) this.hostRefRfid = this.hostRefRfid.substring(0, 13)
      this.hostRefRfid = this.hostRefRfid.replace(/[^A-Za-z1-9]/g, "").toLowerCase()
    },
    rfidHandler() {
      if (this.$refs.rfidform.validate()) {
        window.localStorage.removeItem('TimerPwa_weekno')
        window.localStorage.removeItem('TimerPwa_year')
        this.loading = true
        let hostRefString = this.hostRefRfid.toLowerCase()
        hostRefString = hostRefString.replace(/[^0-9a-zA-Z]/gi, '') // to trim the space and spection characters
        if (!hostRefString.includes(this.defaultHost)) {
          this.model.host = ''
          this.model.host += `${hostRefString}${this.defaultHost}`
        } else this.model.host = `${hostRefString}`
        const authObj = { rfidnumber: this.from.password }
        this.$api.execute('post', `https://${this.model.host}/api/loginrfid`, authObj).then(reponse => {
          if (reponse && reponse.data) {
            const responseData = reponse.data
            if (responseData.statusCode === 500) {
              let message = responseData[`${this.$i18n.locale}_message`] || responseData.message
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: message }) // responseData[`${this.$i18n.locale}_message`]
              this.loading = false
            } else {
              var date = new Date()
              date.setDate(date.getDate() + 4)
              this.model.password = reponse.data.password
              this.model.username = reponse.data.user.email
              this.model.hostAppToken = reponse.data.token
              const timerPwaAppUser = reponse.data.user.user_id
              this.$cookie.set(process.env.VUE_APP_HOST_USER, timerPwaAppUser, { expires: date })
              this.$cookie.set(process.env.VUE_APP_HOST_TOKEN, reponse.data.token, { expires: date })
              this.$cookie.set(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN, reponse.data.checklist_access_token, { expires: date })
              /* set user permission and roles  */
              let obj = JSON.stringify({ permissions: reponse.data.permissions || {}, role: reponse.data.role || {} })
              if (obj) {
                this.$cookie.set(process.env.VUE_APP_PERMISSIONS_AND_ROLES, this.$formatter.encryptString(obj), { expires: date })
                this.$store.commit('setPermissions', JSON.parse(obj))
              }
              /* set the taskcount in cookie and read it anywhere */
              window.localStorage.setItem(process.env.VUE_APP_TASKS_COUNT, reponse.data.task_count || 0)
              /* set initial with model and get from there */
              this.model.initialer = responseData.user.initialer || ''
              /* The app login begins from here */
              this.$store.dispatch('login', this.model)
                .then((userObj) => {
                  const timerStartup = userObj.timer_startup
                  /* On sucess Login store the domain */
                  window.localStorage.setItem(process.env.VUE_APP_DOMAIN_CACHE_NAME, hostRefString)
                  /* var date = new Date()
                  date.setDate(date.getDate() + 4)
                  this.$api.execute('get', 'generalsettings')
                    .then((response) => {
                      if (response.data && response.data[0]) {
                        const model = response.data[0]
                        const isProjectExcluded = model.exclude_project_module !== undefined && model.exclude_project_module !== null ? model.exclude_project_module : false
                        const isDeviationExcluded = model.exclude_deviation_module !== undefined && model.exclude_deviation_module !== null ? model.exclude_deviation_module : false
                        this.$store.commit('isProjectExcluded', isProjectExcluded)
                        this.$store.commit('isDeviationExcluded', isDeviationExcluded)
                        const obj = { project: isProjectExcluded ? 1 : 0, deviation: isDeviationExcluded ? 1 : 0, expires_at: date }
                        this.$cookie.set('pwaAppExcludeModules', JSON.stringify(obj), { expires: date })
                      }
                    }) */
                  const self = this
                  try {
                    // if (('Notification' in window)) {
                    //   Notification.requestPermission().then(function (result) {
                    //     self.$api.execute('get', `hours/get_not_approved_entries_count/${userObj.id}`)
                    //       .then((res) => {
                    //         const weekDetails = res.data
                    //         let showWeekDetails = ''
                    //         if (weekDetails.length > 0) {
                    //           for (let i = 0; i < weekDetails.length; i++) {
                    //             if (weekDetails.length === (i + 1)) showWeekDetails += weekDetails[i]
                    //             else showWeekDetails += weekDetails[i] + ' , '
                    //           }
                    //           if ('serviceWorker' in navigator) {
                    //             navigator.serviceWorker.getRegistration().then(function (reg) {
                    //               var options = {
                    //                 body: self.$t('message.common.not_approved_notification', { count: weekDetails.length, weeknumber: showWeekDetails }),
                    //                 icon: require(`../../../public/img/${self.notificationImage}.png`)
                    //               }
                    //               if (reg) {
                    //                 reg.showNotification(self.notificationTitle, options)
                    //               }
                    //             })
                    //           }
                    //         }
                    //       })
                    //   })
                    //   this.$eventBus.$emit('checkHoursExceeding')
                    // }
                  } catch (error) { }
                  /* get and set features */
                  this.$hostAppApi.execute('get', `https://${this.model.host}/api/check_and_get_tenant_features?user_id=${timerPwaAppUser}`)
                    .then((response) => {
                      if (response && response.data && response.data.features) {
                        window.localStorage.setItem(process.env.VUE_APP_FEATURES_LIST, this.$formatter.encryptString(JSON.stringify(response.data.features)))
                      }
                    })
                    .finally(() => {
                      timerStartup === 1 ? this.$router.push('/calendar') : timerStartup === 2 ? this.$router.push('/stemp') : this.$router.push('/dashboard')
                    })
                })
                .catch((e) => {
                  this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.pwaNotEnabled' })
                })
                .finally(() => {
                  this.loading = false
                })
            }
          }
        }).catch((e) => {
          console.log('Host app api login exception:' + e)
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.login.authFailed') })
          this.loading = false
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    rfidClick() {
      this.signModel = true;
      // this.$refs.passwordField.$refs.input.focus();
      this.$nextTick(() => {
        // Access the reference and focus on the element
        this.$refs.passwordField.$refs.input.focus();
      });
    },
    signInPassword() {
      this.signModel = false;
      // if (this.$refs.rfidHandler.validate()) {
      //   window.localStorage.removeItem('TimerPwa_weekno')
      //   window.localStorage.removeItem('TimerPwa_year')
      //   this.loading = true
      //   let hostRefString = this.hostRef.toLowerCase()
      //   hostRefString = hostRefString.replace(/[^0-9a-zA-Z]/gi, '')
      //   if (!hostRefString.includes(this.defaultHost)) {
      //     this.model.host = ''
      //     this.model.host += `${hostRefString}${this.defaultHost}`
      //   } else this.model.host = `${hostRefString}`
      //   const authObj = { email: this.from.username, password: this.from.password }
      // }
    }
  }
}
</script>
<style>

.border-styles {
  border-top-right-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
}

.login .v-sheet--offset {
  top: -30px;
  position: relative;
}

.login-layout {
  position: fixed;
  background-color: #EEF1F5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.new-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 500px;
  padding: 0 15px;
}
@media only screen and (max-width: 575px) {
  .new-flex {
    max-width: 100%;
  }
}

.card-box {
  padding: 30px 25px !important;
  border-radius: 10px !important;

}

.button-deign {
  width: 100%;
  padding: 14px 20px !important;
  border-radius: 18px !important;
  background-color: #3773e8 !important;
  height: unset !important;
}

.button-deign span {
  color: #fff;
}
</style>
